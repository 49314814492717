.sortableBlock {
    flex: 0 0 25%;
    width: 25%;
    height: 92px;
    overflow: hidden;
    padding: 7px;
    position: relative;
}

.removeButton {
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 3px;
    top: 3px;
    cursor: pointer;
    outline: 0;
    z-index: 2;
}

.sortableImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
}

.flexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.buttonAddAvatar {
    width: 64px;
    height: 64px;
    border-radius: 100px;
    outline: 0;
    border: 0;
    cursor: pointer;
    background-color: #ECECEC;
    margin-right: 15px;
    position: relative;
}

.firstCol {
    text-align: right;
    margin-right: 10px;
    padding-top: 7px;
    color: #262626;
    font-weight: 200;
}

.addPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ECECEC;
    border: 0;
    outline: 0;
    cursor: pointer;
    width: 100%;
    height: 79px;
    border-radius: 4px;
}

.contactItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 10px;
}

.selectOption {
    flex: 0 0 30%;
    margin-right: 10px !important;
}
