* {
  font-family: 'Roboto', sans-serif;
}

.ant-menu-dark a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark a:hover,
.ant-menu-item-selected a {
  color: white;
}

.ant-custom-layout {
  padding: 25px;
  background-color: white;
}

.logo {
  height: 32px;
  margin: 16px;
  display: flex;
  color: white;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  padding: 24px;
  background: #fff;
}

.site-layout {
  min-height: 100vh;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}

.form-row-vertical-padding {
  padding: 0.65em;
}

.avatar-uploader.aspect-half .ant-upload-list-item-list-type-picture-card {
  width: 420px !important;
}

.avatar-uploader.aspect-half .ant-upload-list-item-list-type-picture-card .ant-upload-list-item-image {
  object-fit: cover !important;
}

.blocks-with-banners .ant-card-body {
  padding: 0 !important;
}

.banner-image .ant-upload.ant-upload-btn {
  padding: 0 !important;
}
