.header-content-container {
  flex: 1;
  justify-content: space-between;
  margin-right: 1%;
}

.header-content-container-centered {
  flex: 1;
  display: flex;
  align-items: center;
}

.header-content-container-icon {
  font-size: 20px !important;
  margin-left: 3% !important;
}

.header-content-avatar-wrapper:hover {
  cursor: pointer;
}

.header-content-avatar-wrapper {
  max-width: 250px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.header-content-avatar-main {
  line-height: 17px;
  flex: 1;
  overflow: hidden;
  margin-left: 4.5%;
  /* border: 1px solid black; */
}

.header-content-avatar-main-user-name {
  color: black;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header-content-avatar-main-user-type {
  font-size: 13px;
}
.header-content-avatar-chevron {
  flex: 0.2;
  color: black;
}
