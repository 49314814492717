.select {
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-box-shadow: -2px 0px 23px -6px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: -2px 0px 23px -6px rgba(0, 0, 0, 0.63);
  box-shadow: -2px 0px 23px -6px rgba(0, 0, 0, 0.63);
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
}

.select::-webkit-scrollbar {
  width: 6px;
}

.select::-webkit-scrollbar-thumb {
  background-color: silver;
  /* outline: 1px solid slategrey; */
  border-radius: 5px;
}
