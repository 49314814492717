.table-history-wrapper {
  width: 38%;
  height: 100px;
  position: absolute;
  margin-top: -15px;
  display: flex;
  flex-direction: row;
}

.table-history-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
