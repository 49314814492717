.custom-row-margin {
  margin-bottom: 23px;
}

.user-avatar {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.user-element-text-main {
  font-weight: 400;
  margin-right: 3%;
  color: black;
}

.user-avatar-name {
  margin-left: 5%;
}

.user-element-dropdown-button {
  text-decoration: none !important;
  color: black;
}

.user-element-dropdown-selected {
  margin-top: 10px;
  font-weight: 300;
  font-size: 13px;
}

.user-element-dropdown-selected-centered {
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.user-avatar-full-name {
  color: black;
  /*margin-bottom: 4px;*/
}
