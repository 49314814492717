.ant-select-selection-item .category-selector {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.date-picker-require .ant-picker-input > input:empty::placeholder {
  color: #ff5757;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

@media (max-width: 480px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }

  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-thead > tr > th {
    white-space: pre;
  }

  .ant-table-tbody > tr > td > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-thead > tr > th > span {
    display: block;
  }
}

.ant-form-item-label {
  line-height: 25px !important;
}

@media (max-width: 575px) {
  .ant-form-item-label {
    padding: 0 !important;
  }
}

.control-panel {
  background: rgba(0, 0, 0, 0.5);
  color: #fff !important;
  position: absolute;
  bottom: 40px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
}

.mapboxgl-user-location-dot {
  transform: translate(-50%, -100%);
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  /* height: 300px !important; */
  /* float: none !important; */
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 250px !important;
  height: 250px !important;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload.ant-upload-select-picture-card {
  width: 250px !important;
  height: 250px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 30px !important;
  text-align: center;
}

.custom-modal .ant-modal-close {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 24px !important;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .latitude-input {
    padding-right: 8px !important;
  }

  .longitude-input {
    padding-left: 8px !important;
  }
}

.ant-carousel .slick-slide {
  overflow: hidden;
}

.ant-carousel .slick-slide img {
  height: 550px;
  width: auto;
  margin: 0 auto;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  opacity: 1 !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

.ant-comment-inner {
  padding: 0 !important;
}

.comment .ant-comment-inner {
  margin-bottom: 16px !important;
}

.user-table tbody tr,
.sculpture-table tbody tr {
  cursor: pointer !important;
}

.ant-descriptions-item-label {
  font-weight: 500 !important;
}

.ant-descriptions-item-content {
  margin-bottom: 12px !important;
}

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 0px !important;
}

.ant-comment-content-author-time {
  flex: 1 !important;
}

.comment-list .ant-list-pagination {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ant-dropdown-menu.date-menu {
  padding: 0px !important;
  box-shadow: none !important;
}

.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 4px !important;
}

.admin-comment .ant-comment-content-author {
  margin-bottom: 0px !important;
}

body {
  margin: 0;
  line-height: normal;
}

* {
  padding: 0;
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
